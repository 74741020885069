import { forwardRef, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderTitle, setHeaderActionBtns, setSearchValue, setShowPremiumHeader } from 'src/plugins/redux/reducers/HeaderReducer';
import HeaderMessage from "src/componenets/HeaderMessage";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { useSelector } from "react-redux";




const Header = forwardRef((props: any, ref: any): JSX.Element => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const currentUser = useSelector(currentUserSelector);

  const headerContent = useSelector((state) => state.header);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    dispatch(setSearchValue(inputValue));
  }

  useEffect(() => {
    dispatch(setShowPremiumHeader(false));
  }, [location, dispatch]);


  return (
    <div id="kt_header" className="header header-fixed">
      <div id="kt_new_header" className="col-12 col-md-12">
        {!currentUser?.is_premium && currentUser?.role_id == '3' && headerContent.showPremiumHeader && <HeaderMessage />}
        <div className="row justify-content-between">
          <div className="col-12 col-md-12 pt-5 pl-10 d-flex align-items-center gap-1">
            {headerContent.arrowBack &&
              <button className="btn btn-outline-custom-primary rounded-circle p-3" onClick={() => {
                navigate(-1);
              }}>
                <i className="fa fa-arrow-left p-0"></i>
              </button>
            }
            <span id="kt_title">{headerContent.title}</span>

          </div>
          {headerContent.showSearch &&
            <div className="col-12 col-md-4 pt-4">
              <div className="input-group">
                <div id="kt_search_icon" className="input-group-prepend">
                  <i className="flaticon-search text-custom-primary"></i>
                  <div className="search-sep"></div>
                </div>
                <input type="text" id="kt_search"
                  onChange={handleInputChange}
                  className="header-search form-control form-control-solid bg-secondary border-0 rounded-pill pl-13"
                  placeholder="Search" />
              </div>
            </div>
          }
          {headerContent.actionBtns.length > 0 &&
            <div className="col-12 col-md-5 d-flex align-items-center gap-2 justify-content-end">
              {headerContent.actionBtns.map((btn, index) => {
                return (
                  <Link to={btn.link} key={`header-link-${index}`}>
                    <button type="button" className="btn btn-pill btn-custom-primary">
                      {btn.text}
                    </button>
                  </Link>
                );

              })}
            </div>
          }
        </div>
        <div className="kt_hr_light mt-4"></div>
      </div>
    </div >
  );
});

export default Header;
